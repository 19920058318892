<template>
  <div
    class="pinyin-move-game-container layout-border"
    :class="'lesson' + lessonNum"
  >
    <div class="move-game-box position-relative">
      <PageButton
        :bottomCornerButtonList="bottomCornerButtonList"
        :isLastStep="isLastStep"
        @clickNext="nextPage"
      ></PageButton>
      <div class="move-game-area">
        <div
          class="move-game-content"
          @click="handleClickBgImg"
          ref="moveGameBox"
        >
          <!-- 背景图 -->
          <img :src="bgImg" alt="" />
          <img :src="frontImg" alt="" class="second-img" v-if="frontImg" />

          <div
            class="student-img"
            ref="student"
            :style="{ width: scrollWidth + 'px', height: '100%' }"
          >
            <div class="object-wrapper">
              <div class="move-wrapper" :class="'step-' + step">
                <div class="flame" :class="{ flameIsVisible: isMoving }"></div>
                <img
                  :src="studentImg"
                  alt=""
                  class="move-block"
                  v-if="studentImg"
                />
              </div>
            </div>
          </div>
          <div
            class="bottom-sentence-area"
            v-if="textToShow && textToShow[step].pinyin"
            :class="{fadeIn : fadeInText, isHidden : textIsHidden}"
          >
            <div
              class="hanzi-item"
              :class="{ blueBG: isBlueBackground }"
            >
              <div class="pinyin sentence-hanzi font-pinyin-medium">
                {{ textToShow[step].pinyin }}
              </div>
              <div class="sentence-hanzi font-hanzi-medium">{{ textToShow[step].hanzi }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";

import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";

export default {
  components: {
    PageButton,
  },
  props: {
    bgImg: {
      type: String,
      require: true,
    },
    studentImg: {
      type: String,
      require: false,
    },
    lessonNum: {
      type: Number,
      require: true,
    },

    durationArr: {
      type: Array,
      require: true,
    },
    frontImg: {
      type: String,
      require: false,
    },
    textToShow: {
      type: Array,
      require: false,
    }
  },
  data() {
    return {
      success_audio_sound: require("@/assets/audio/correct_answer.mp3"),
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },

      step: 0,
      scrollDom: null,
      scrollWidth: null,
      distance: 0,
      scrollSpeed: 5,
      clickSocketInfo: {},
      isMoving: false,
      flag: false,
      fadeInText: false
    }
  },

  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.scrollDom = this.$refs.moveGameBox;
        this.scrollWidth = this.scrollDom.scrollWidth;
      });
    }, 700);
    this.$bus.$on("clicPinyinkMoveGame", () => {
      this.handleClickBgImg("socket", true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("clicPinyinkMoveGame");
  },
  watch: {
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20039,
          data: { value },
          text: "PinyinMoveGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    controlScroll(step) {
      this.scrollMove(this.durationArr[step - 1]);
    },
    scrollMove(num) {
      const speed = this.scrollSpeed;
      this.isMoving = true;
      let timer = setInterval(() => {
        this.distance += speed;
        this.scrollDom.scrollLeft = this.distance;
        if (this.distance / this.scrollWidth >= num) {
          console.log("+++++++++++++++++++");
          clearInterval(timer);
          this.isMoving = false;

          if (this.step === this.durationArr.length) {
            this.isLastStep = true;
            playCorrectSound();
            startConfetti();
          } else {
            playCorrectSound(true, false);
          }
        }
      }, 10);
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleClickBgImg(socket, isFromSocket = false) {
      this.fadeInText = false;
      this.textIsHidden = true;
      console.log(this.flag);
      
      if (this.isMoving) {
        return;
      }
      if (this.step >= this.durationArr.length) {
        return;
      }
      if (!isFromSocket) {
        this.clickSocketInfo = {
          value: Math.random(),
        };
      }
      if (this.step >= this.durationArr.length + 1) {
        return;
      }
      this.step++;
      var _this = this;
      setTimeout(function(){
        if (_this.textToShow[_this.step].pinyin) {
          _this.textIsHidden = false;
          _this.fadeInText = true;
        } else {
          _this.fadeInText = false;
        }
        
      }, 1500)

      if (this.step >= 1) {
        this.controlScroll(this.step);
      }

      //   this.isLastStep = true;
      //   this.step = this.durationArr.length;
      //   playCorrectSound();
      //   startConfetti();
    },
  },
};
</script>
<style scoped lang="scss">
.pinyin-move-game-container {
  position: relative;
  .move-game-box {
    width: 100%;
    height: 100%;
    background-color: #f7f4e0;
    border-radius: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    position: relative;
    .move-game-area {
      width: 100%;
      height: 100%;
      white-space: nowrap;
      border-radius: 58px;
      .move-game-content {
        height: 100%;
        position: relative;
        border-radius: 58px;

        :hover {
          cursor: pointer;
        }
        overflow-x: scroll;
        img {
          // width: 100%;
          height: 100%;
        }
        .second-img {
          position: absolute;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 7;
        }
        .student-img {
          position: absolute;
          left: 0%;
          top: 0;
          .object-wrapper {
            z-index: 5;
            height: 100%;
            .move-wrapper {
              display: inline-block;

              position: absolute;
              transition: all 2.5s;
              bottom: 25%;
              left: 0%;
              .move-block {
                width: 60%;
              }
            }
          }
        }
      }
    }
    ::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }

    .bottom-sentence-area {
      width: 100%;
      height: 20%;
      bottom: 4%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: sticky;
      left: 0;
      .hanzi-item {
        // width: 50%;
        margin: 0 auto;
        background: #cd4c3f;
        position: absolute;
        padding: 1% 15%;
        color: #fff;
        border-radius: 40px;
        .sentence-hanzi,
        .sentence-pinyin {
          text-align: center;
        }
      }
      .blueBG {
        background: #224e96;
        // padding: 1% 5%;
      }
      &.higherPosition {
        bottom: 8%;
      }
    }

}

.lesson7 {
  .step-1 {
    bottom: 26% !important;
    left: 5% !important;
  }
  .step-2 {
    bottom: 38% !important;
    left: 24% !important;
  }

  .step-3 {
    bottom: 36% !important;
    left: 41% !important;
  }

  .step-4 {
    bottom: 45% !important;
    left: 64% !important;
  }

  .step-5 {
    bottom: 38% !important;
    left: 84% !important;
  }
  .step-6 {
    bottom: 54% !important;
    left: 96% !important;
  }
}

.lesson10 {
  .step-1 {
    bottom: 24vh !important;
    left: 17vh !important;
  }
  .step-2 {
    bottom: 38vh !important;
    left: 54vh !important;
  }
  .step-3 {
    bottom: 20vh !important;
    left: 90vh !important;
  }
  .step-4 {
    bottom: 40vh !important;
    left: 150vh !important;
  }
}
.lesson15 {
  .step-1 {
    bottom: 26% !important;
    left: 3% !important;
  }
  .step-2 {
    bottom: 34% !important;
    left: 14% !important;
  }

  .step-3 {
    bottom: 30% !important;
    left: 25% !important;
  }

  .step-4 {
    bottom: 45% !important;
    left: 39% !important;
  }

  .step-5 {
    bottom: 42% !important;
    left: 51% !important;
  }
  .step-6 {
    bottom: 46% !important;
    left: 62% !important;
  }
  .step-7 {
    bottom: 39% !important;
    left: 74% !important;
  }
  .step-8 {
    bottom: 54% !important;
    left: 86% !important;
  }
  .step-9 {
    bottom: 59% !important;
    left: 96.8% !important;
  }
}
.lesson16 {
  .move-block {
    transition: all 1.5s !important;
  }
  .move-wrapper {
    left: 5% !important;
  }
  .step-1 {
    bottom: 22% !important;
    left: 13% !important;
  }

  .step-2 {
    bottom: 22% !important;
    left: 32% !important;
  }

  .step-3 {
    bottom: 22% !important;
    left: 49% !important;
  }

  .step-4 {
    bottom: 22% !important;
    left: 70% !important;
  }
}
.lesson23 {
  .student-img {
    bottom: 22% !important;
    left: 10% !important;
  }
  .step-1 {
    bottom: 26% !important;
    left: 28% !important;
  }

  .step-2 {
    bottom: 14% !important;
    left: 80% !important;
  }

  .step-3 {
    bottom: 37% !important;
    left: 119% !important;
  }

  .step-4 {
    bottom: 8% !important;
    left: 160% !important;
  }
  .step-5 {
    bottom: 38% !important;
    left: 200% !important;
  }
  .step-6 {
    bottom: 28% !important;
    left: 256% !important;
  }
  .step-7 {
    bottom: 48% !important;
    left: 316% !important;
  }
  .step-8 {
    bottom: 28% !important;
    left: 362% !important;
  }
  .step-9 {
    bottom: 57% !important;
    left: 468% !important;
  }
}

.lesson18 {
  .student-img {
    bottom: 20% !important;
    left: 15% !important;

    .move-block {
      width: 90% !important;
    }
  }
  .step-1 {
    bottom: 18% !important;
    left: 8% !important;
  }

  .step-2 {
    bottom: 21% !important;
    left: 22% !important;
  }

  .step-3 {
    bottom: 21% !important;
    left: 35% !important;
  }

  .step-4 {
    bottom: 18% !important;
    left: 46% !important;
  }

  .step-5 {
    bottom: 17% !important;
    left: 59% !important;
  }

  .step-6 {
    bottom: 18% !important;
    left: 72% !important;
  }
}
.lesson25 {
  .student-img {
    bottom: 20% !important;
    left: 15% !important;
  }
  .step-1 {
    bottom: 40% !important;
    left: 9% !important;
  }

  .step-2 {
    bottom: 21% !important;
    left: 22% !important;
  }

  .step-3 {
    bottom: 21% !important;
    left: 45% !important;
  }

  .step-4 {
    bottom: 47% !important;
    left: 60% !important;
  }
}

.lesson32 {
  .student-img {
    bottom: 23% !important;
    left: 3% !important;
  }
  .step-1 {
    bottom: 20% !important;
    left: 3% !important;
  }

  .step-2 {
    bottom: 32% !important;
    left: 18% !important;
  }

  .step-3 {
    bottom: 27% !important;
    left: 30% !important;
  }

  .step-4 {
    bottom: 18% !important;
    left: 41% !important;
  }

  .step-5 {
    bottom: 12% !important;
    left: 58% !important;
  }

  .step-6 {
    bottom: 14% !important;
    left: 74% !important;
  }
}

.lesson320 {
  .move-block {
    transition: all 1.5s !important;
    width: 71% !important;

  }
  .move-wrapper {
    left: 5% !important;
  }
  .step-1 {
    bottom: 60% !important;
    left: 13% !important;
    .move-block {
      width: 50% !important;
    }
  }

  .step-2 {
    bottom: 37% !important;
    left: 32% !important;
  }

  .step-3 {
    bottom: 60% !important;
    left: 49% !important;
    .move-block {
      width: 50% !important;
    }
  }

  .step-4 {
    bottom: 22% !important;
    left: 70% !important;
  }
}

.lesson50 {
  .student-img {
    bottom: 23% !important;
    left: 6% !important;

    .move-wrapper img {
      width:73% !important;
    }
  }
  .step-1 {
    bottom: 26% !important;
    left: 6% !important;
  }

  .step-2 {
    bottom: 36% !important;
    left: 28% !important;
  }

  .step-3 {
    bottom: 27% !important;
    left: 55% !important;
  }

  .step-4 {
    bottom: 35% !important;
    left: 79% !important;
  }
}
.lesson51 {
  .student-img {
    top: -25% !important;
    left: 7% !important;
  }
  .step-1 {
    bottom: 38% !important;
    left: 5% !important;
  }

  .step-2 {
    bottom: 28% !important;
    left: 25% !important;
  }

  .step-3 {
    bottom: 48% !important;
    left: 54% !important;
  }

  .step-4 {
    bottom: 30% !important;
    left: 77% !important;
  }
}
.lesson56 {
  .student-img {
    top: -25% !important;
    left: 7% !important;
  }
  .step-1 {
    bottom: 35% !important;
    left: 12% !important;
  }

  .step-2 {
    bottom: 28% !important;
    left: 36% !important;
  }

  .step-3 {
    bottom: 38% !important;
    left: 68% !important;
  }
}
</style>
